import { Component, inject, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { PaginationModel, TableHeaderItem, TableItem, TableModel } from 'carbon-components-angular';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DOCUMENT } from '@angular/common';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'dial',
	templateUrl: './dial.component.html',
	styleUrls: ['./dial.component.css']
})

export class DialComponent implements OnInit {

	email = '';
	password = '';

	authenticated = false;

	dialing = false;
	subscription: any;

	isCall = true;
	isLogs = false;
	logSpinner = false;

	theme = 'white';

	docRef: any;
	voice = '';

	remainingMinutes = null;
	fromNum = '';
	firstSentence = '';
	conversationFlow = '';
	varValues = '';
	varNames = '';

	voices = [
	{
      content: 'Male',
      selected: false,
      disabled: true,
    },
	{
      content: '5lm1mr2qVzTTtc8lNLgo',
      selected: false,
      disabled: false,
    },
	{
      content: '6OzrBCQf8cjERkYgzSg8',
      selected: false,
      disabled: false,
    },
	{
      content: 'Female',
      selected: false,
      disabled: true,
    },
	{
      content: '679VwyAsM8ZgtPmHNhd4',
      selected: false,
      disabled: false,
    },
	{
      content: 'QXQtfMWdj167BDWHhyAP',
      selected: false,
      disabled: false,
    }
  ];

	call_minutes = '';
	num_calls = '';
	total_spent = '';
	time_per_call = '';

	pagination = new PaginationModel();
	logsData = new TableModel();
	showSaveNotification = false;
	showDialNotification = false;

	@ViewChild('overflowMenuItemTemplate', { static: false })
	protected overflowMenuItemTemplate!: TemplateRef<any>;

	// getStartAndEndOfDay(isoDateString: string): { startOfDay: string, endOfDay: string } {
	//   // Parse the ISO date string into a Date object
	//   const date = new Date(isoDateString);
	//
	//   // Create a new Date object for the start of the day
	//   const startOfDay = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0);
	//
	//   // Create a new Date object for the end of the day
	//   const endOfDay = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 23, 59, 59, 999);
	//
	//   // Return the timestamps
	//   return {
	// 	startOfDay: startOfDay.getTime(),
	// 	endOfDay: endOfDay.getTime()
	//   };
	// }

	selectedDate: any;
	modalIsOpen = false;
	usageModalIsOpen = false;
	call_details: any = '';

	call_id: any = '';

	call_recording_url: any = '';

	call_to_number: any = '';
	call_started: any = '';
	call_ended: any = '';
	call_end_reason: any = '';

	call_summary: any = '';
	call_messages = [];
	rightSideOpen: boolean = false;

	private document = inject(DOCUMENT);
	private renderer = inject(Renderer2);

	constructor(
		private http: HttpClient,
		private titleService: Title,
		private afAuth: AngularFireAuth,
		private afStore: AngularFirestore,
	  ) {}

	extractUniqueVariables(template: string): string[] {
		const regex = /{{(.*?)}}/g;
		const matches = new Set<string>();
		let match;

		while ((match = regex.exec(template)) !== null) {
			matches.add(match[1].trim());
		}

		const templatItems = Array.from(matches);
		templatItems.unshift('phone_number');

		return templatItems;
	}

	navigateToCall() {
		this.isCall = true;
		this.isLogs = false;
		this.afStore.collection('prompts').doc(this.docRef).update({
		  is_call: this.isCall,
	    });
		this.titleService.setTitle('Dial - ELS');
		// this.titleService.setTitle('Dial - Lomni AI');
	}

	async navigateToLogs() {
		this.isCall = false;
		this.isLogs = true;
		this.logSpinner = true;
		const temp = new Date().toISOString();
		const result = this.getStartAndEndOfDay(temp);
		console.log(result);
		this.selectedDate = new Date(result.startOfDay);
		console.log(this.selectedDate);
		this.selectDate(this.selectedDate);
		await this.afStore.collection('prompts').doc(this.docRef).update({
			is_call: this.isCall,
		});
		// this.titleService.setTitle('Logs - Lomni AI');
		this.titleService.setTitle('Logs - ELS');
	}

	async ngOnInit() {
	}

	selected(event: any) {
		this.voice = event.item.content;
		this.voices.forEach(voice => {
			voice.selected = voice.content === this.voice;
		});
		// console.log('this.voice', this.voice);
	}

	convertVoicesToJSON(voices: any): string {
	  return JSON.stringify(voices, null, 2);
	}

	save() {
		const voicesJson = this.convertVoicesToJSON(this.voices);
		// console.log('this.first_sentence', this.first_sentence);
		this.afStore.collection('prompts').doc(this.docRef).update({
		  	voice: this.voice,
			voices: voicesJson,
		  	first_sentence: this.firstSentence,
		  	conversation_flow: this.conversationFlow,
	    });
		this.showSaveNotification = true;
		setTimeout(() => {
		  this.showSaveNotification = false;
		}, 5000);

		this.varNames = this.extractUniqueVariables(this.firstSentence + this.conversationFlow).join(', ');
	}

	onThemeChange() {
		// Remove current assigned class then add updated class
		this.renderer.removeClass(this.document.body, this.theme);
		switch (this.theme) {
			case 'white':
				this.theme = 'g100';
				break;
			// case 'g10':
			// 	this.theme = 'g90';
			// 	break;
			// case 'g90':
			// 	this.theme = 'g100';
			// 	break;
			case 'g100':
				this.theme = 'white';
				break;
			default:
				this.theme = 'white';
		}
		this.renderer.addClass(this.document.body, this.theme);
		this.afStore.collection('prompts').doc(this.docRef).update({
		  theme: this.theme,
	    });
	}

	dial() {

		this.dialing = true;
		// const url = 'https://lomni.ngrok.app/dial';
		const url = 'https://backend-6dydoxpvza-bq.a.run.app/dial';
		const data = {
			'voice': this.voice,
			'from_num': this.fromNum,
			'var_names': this.varNames,
			'var_values': this.varValues,
			'first_sentence': this.firstSentence,
			'conversation_flow': this.conversationFlow,
		};
		// console.log(data);
		this.http.post(url, data).subscribe(response => {
		  this.dialing = false;
		  this.showDialNotification = true;
			setTimeout(() => {
			  this.showDialNotification = false;
			}, 5000);
		});
  	}
	  ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	onKeyUpFirstSentence($event: KeyboardEvent) {
		this.firstSentence = ($event.target as HTMLInputElement).value;
		this.varNames = this.extractUniqueVariables(this.firstSentence + this.conversationFlow).join(', ');
	}

	onKeyUpEmail($event: KeyboardEvent) {
		this.email = ($event.target as HTMLInputElement).value;
	}

	onKeyUpPassword($event: KeyboardEvent) {
		this.password = ($event.target as HTMLInputElement).value;
	}

	onKeyUpConversationFlow($event: KeyboardEvent) {
		this.conversationFlow = ($event.target as HTMLInputElement).value;
		this.varNames = this.extractUniqueVariables(this.firstSentence + this.conversationFlow).join(', ');
	}

	getSouthAfricanTime(time: { getTime: () => number; }) {
		const southAfricaTimeOffset = 2 * 60; // UTC+2 in minutes
		const southAfricaTime = new Date(time.getTime() + southAfricaTimeOffset * 60000);

		// Get the ISO string representation of the South Africa time
		return southAfricaTime.toISOString();
	}

	getStartAndEndOfDay(isoDateString: string): { startOfDay: string, endOfDay: string } {
  // Parse the ISO date string into a Date object
	  const date = new Date(isoDateString);

	  // Adjust the date to South African time (UTC+2)
	  const timeOffset = 2 * 60; // SAST is UTC+2 hours
	  const localDate = new Date(date.getTime() + timeOffset * 60 * 1000);

	  // Create a new Date object for the start of the day in SAST
	  const startOfDay = new Date(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate(), 0, 0, 0, 0);
	  startOfDay.setMinutes(startOfDay.getMinutes() - startOfDay.getTimezoneOffset() - timeOffset);

	  // Create a new Date object for the end of the day in SAST
	  const endOfDay = new Date(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate(), 23, 59, 59, 999);
	  endOfDay.setMinutes(endOfDay.getMinutes() - endOfDay.getTimezoneOffset() - timeOffset);

	  // Return the ISO strings
	  return {
		startOfDay: startOfDay.toISOString(),
		endOfDay: endOfDay.toISOString()
	  };
	}

	selectDate(date: Date) {

		this.selectedDate = date;

		this.time_per_call = '';
		this.num_calls = '';
		this.call_minutes = '';
		this.total_spent = '';
		this.logsData.data = [];

		const results = this.getStartAndEndOfDay(date.toISOString());

		// const url = 'https://lomni.ngrok.app/logs';
		const url = 'https://backend-6dydoxpvza-bq.a.run.app/logs';
		const data = {
			'from_num': this.fromNum,
			'start': results.startOfDay,
			'end': results.endOfDay
		};

		this.http.post(url, data).subscribe(response => {
			// console.log(response);
			// @ts-ignore
			this.time_per_call = response['average_minutes_per_call'];
			// @ts-ignore
			this.num_calls = response['num_of_calls'];
			// @ts-ignore
			this.call_minutes = response['total_call_minutes'];
			// @ts-ignore
			this.total_spent = response['total_spent'];
			// @ts-ignore
			response['calls'].forEach((call: any) => {
				// console.log('call', call);
				this.logsData.data.push([
					new TableItem({data: call['phone_number']}),
					new TableItem({data: call['created_at']}),
					new TableItem({data: call['call_id']}),
					new TableItem({ data: { vapi_id: call['vapi_id'], call_id: call['call_id'] }, template: this.overflowMenuItemTemplate })
				]);
			});
			// remove first object from this.logsData.data
			this.logsData.data = this.logsData.data.slice(1);
			// this.pagination.pageLength = this.logsData.data.length / 10;
			// this.pagination.currentPage = 1;
			// console.log(this.logsData.data);
			this.logSpinner = false;
		});
	}

	capitalize(str: string): string {
		return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
	}

	openModal(data: any) {
		this.call_details = '';

		console.log('data', data);

		// const url = 'https://lomni.ngrok.app/details';
		const url = 'https://backend-6dydoxpvza-bq.a.run.app/details';
		const payload = {
			'vapi_id': data.vapi_id,
			'call_id': data.call_id,
		};
		// console.log(data);
		this.http.post(url, payload).subscribe(response => {
			console.log(response);
			this.call_id = data.call_id;
		  	// @ts-ignore
		  	this.call_recording_url = response['recordingUrl'];
			  console.log(this.call_recording_url);
		  	// @ts-ignore
		  	this.call_to_number = response['customer']['number'];
		  	// @ts-ignore
		  	this.call_started = response['startedAt'];
		  	// @ts-ignore
		  	this.call_ended = response['endedAt'];
		  	// @ts-ignore
		  	this.call_end_reason = this.capitalize(response['endedReason'].replace(/-/g, ' '));
		  	// @ts-ignore
		  	this.call_summary = response['summary'];
		  	// @ts-ignore
		  	this.call_messages = response['messages'].slice(1);
		  	this.modalIsOpen = true;
		});
	}

	closeModal() {
		this.modalIsOpen = false;
	}

	openUsageModal() {
		this.usageModalIsOpen = true;
	}

	closeUsageModal() {
		this.usageModalIsOpen = false;
	}

	protected readonly JSON = JSON;

	navigateToBuyMin() {
		const baseURL = 'https://buy.stripe.com/3cs3eQaXZ0Yubuw29Q?prefilled_email=';
		const emailComponent = encodeURIComponent(this.email);
		window.open(baseURL + emailComponent, '_blank');
	}

	navigateToCustomerPortal() {
		const baseURL = 'https://billing.stripe.com/p/login/aEUaGK78C0sk6Hu000?prefilled_email=';
		const emailComponent = encodeURIComponent(this.email);
		window.open(baseURL + emailComponent, '_blank');
	}

	navigateToFeedback() {
		// window.open('https://lomni.featurebase.app/', '_blank');
		window.open('https://elscx.featurebase.app/', '_blank');
	}

	clickAvatar() {
		this.rightSideOpen = !this.rightSideOpen;
	}

	async login() {
		this.afAuth.signInWithEmailAndPassword(this.email, this.password).then(async () => {
			const snapshot = await this.afStore.collection(
				'prompts', ref => ref.where('email', '==', this.email),
			).snapshotChanges();

			this.subscription = snapshot.subscribe(docs => {
				// console.log('docs', docs);
				const doc = docs[0].payload.doc;
				// tslint:disable-next-line:variable-name
				const doc_data = doc.data();
				// @ts-ignore
				this.docRef = doc.id;
				// @ts-ignore
				this.voice = doc_data.voice;
				// @ts-ignore
				this.voices = JSON.parse(doc_data.voices);
				this.voices.forEach(voice => {
					voice.selected = voice.content === this.voice;
				});
				// console.log(this.voices);
				// @ts-ignore
				this.remainingMinutes = doc_data.usage;
				// @ts-ignore
				this.fromNum = doc_data.from_num;
				// @ts-ignore
				this.firstSentence = doc_data.first_sentence;
				// @ts-ignore
				this.conversationFlow = doc_data.conversation_flow;
				// @ts-ignore
				this.isCall = doc_data.is_call;
				this.isLogs = !this.isCall;
				if (this.isCall) {
					this.navigateToCall();
				} else {
					this.logSpinner = true;
					this.logsData.header = [
						new TableHeaderItem({
							data: 'Number',
						}),
						new TableHeaderItem({
							data: 'Created At',
						}),
						new TableHeaderItem({
							data: 'Call ID',
						}),
						new TableHeaderItem({
							data: 'Call Logs',
						})
					];
					this.logsData.data = [];
					const temp = new Date().toISOString();
					const result = this.getStartAndEndOfDay(temp);
					console.log(result);
					this.selectedDate = new Date(result.startOfDay);
					console.log(this.selectedDate);
					this.selectDate(this.selectedDate);
					// this.titleService.setTitle('Logs - Lomni AI');
					this.titleService.setTitle('Logs - ELS');
				}
				// @ts-ignore
				this.theme = doc_data.theme;
				this.renderer.addClass(this.document.body, this.theme);

				this.varNames = this.extractUniqueVariables(this.firstSentence + this.conversationFlow).join(', ');

				this.pagination.pageLength = 0;
				this.pagination.currentPage = 0;

				this.authenticated = true;

				if (this.subscription) {
				  this.subscription.unsubscribe();
				}

			});
		}).catch(() => {
			this.authenticated = false;
		});
	}

	logout() {
		// this.titleService.setTitle('Dialpad - Lomni AI');
		this.titleService.setTitle('Dialpad - ELS');
		this.afAuth.signOut().then(() => {
			this.authenticated = false;
			this.rightSideOpen = false;
			if (this.subscription) {
			  this.subscription.unsubscribe();
			}
		});
	}
}
